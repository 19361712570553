export const PUBLIC_ROUTE = {
  LANDING: "/",
  SIGN_IN: "/signin",
  PAGE_404: "/404",
  PAGE_500: "/500",
};

export const PRIVATE_ROUTE = {
  DASHBOARD: "/",
};
