export const GET_STREETS_REQUEST = "GET_STREETS_REQUEST";
export const GET_STREETS_SUCCESS = "GET_STREETS_SUCCESS";

export const SHOW_STREET_REQUEST = "SHOW_STREET_REQUEST";
export const SHOW_STREET_SUCCESS = "SHOW_STREET_SUCCESS";

export const POST_STREET_REQUEST = "POST_STREET_REQUEST";
export const POST_STREET_SUCCESS = "POST_STREET_SUCCESS";

export const PUT_STREET_REQUEST = "PUT_STREET_REQUEST";
export const PUT_STREET_SUCCESS = "PUT_STREET_SUCCESS";

export const DELETE_STREET_REQUEST = "DELETE_STREET_REQUEST";
export const DELETE_STREET_SUCCESS = "DELETE_STREET_SUCCESS";
