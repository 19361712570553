import Cookies from "js-cookie";
import update from "immutability-helper";

const AuthService = {
  securityToken: undefined,
  isAuthenticated: false,
  user: {
    id: undefined,
    username: undefined,
    accessToken: undefined,
  },
  authenticate(user) {
    if (!user) {
      return;
    }

    // Set cookies.
    Object.keys(this.user).forEach((el) => {
      Cookies.set(el, user[el], { expires: 30 });
      localStorage.setItem(el, user[el]);
    });

    this.isAuthenticated = true;
    this.user = user;
  },
  isUserAuthenticated() {
    return this.isAuthenticated;
  },
  init() {
    const tempUser = {};
    let params = new URL(document.location).searchParams;
    let token = params.get("token");

    if (!token) {
      Object.keys(this.user).forEach((el) => {
        tempUser[el] = Cookies.get(el);
      });

      const allCookiesExist = Object.values(tempUser).every(
        (el) => el !== undefined
      );

      if (allCookiesExist) {
        this.authenticate(tempUser);
      }
    } else {
      tempUser.token = token;
    }
  },
  update(data) {
    if (!data) {
      return;
    }

    this.user = update(this.user, {
      $merge: {
        dateCreated: data.dateCreated,
        dateUpdated: data.dateUpdated,
        username: data.username,
        id: data.id,
      },
    });
  },
  logout(noReload) {
    this.isAuthenticated = false;
    this.user = {
      id: undefined,
      username: undefined,
      accessToken: undefined,
    };

    this.reset();

    // !noReload && window.location.reload();
  },
  getUser() {
    return {
      ...this.user,
    };
  },

  reset() {
    // Delete cookies.
    localStorage.removeItem("id_token");
    Object.keys(this.user).forEach((el) => {
      Cookies.remove(el);
      localStorage.removeItem(el);
    });
  },
  setSecurityToken(value) {
    localStorage.setItem("id_token", value);
    Cookies.set("securityToken", value, { expires: 30 });

    this.securityToken = value;
  },
};

export default AuthService;
