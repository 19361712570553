import * as ACTIONS from "@iso/redux/languages/actionTypes";

const initialState = {
  languages: {
    list: [],
    total: 0,
  },
};

function languagesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_LANGUAGES_SUCCESS: {
      return {
        ...state,
        ...{ languages: payload },
      };
    }

    default:
      return state;
  }
}

export default languagesReducer;
