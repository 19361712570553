export const GET_PACKAGES_REQUEST = "GET_PACKAGES_REQUEST";
export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";

export const SHOW_PACKAGE_REQUEST = "SHOW_PACKAGE_REQUEST";
export const SHOW_PACKAGE_SUCCESS = "SHOW_PACKAGE_SUCCESS";

export const POST_PACKAGE_REQUEST = "POST_PACKAGE_REQUEST";
export const POST_PACKAGE_SUCCESS = "POST_PACKAGE_SUCCESS";

export const PUT_PACKAGE_REQUEST = "PUT_PACKAGE_REQUEST";
export const PUT_PACKAGE_SUCCESS = "PUT_PACKAGE_SUCCESS";

export const DELETE_PACKAGE_REQUEST = "DELETE_PACKAGE_REQUEST";
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS";

export const GET_PACKAGES_CATEGORIES_REQUEST = "GET_PACKAGES_CATEGORIES_REQUEST";
export const GET_PACKAGES_CATEGORIES_SUCCESS = "GET_PACKAGES_CATEGORIES_SUCCESS";
