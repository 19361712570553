export const GET_FEATURES_REQUEST = "GET_FEATURES_REQUEST";
export const GET_FEATURES_SUCCESS = "GET_FEATURES_SUCCESS";

export const SHOW_FEATURE_REQUEST = "SHOW_FEATURE_REQUEST";
export const SHOW_FEATURE_SUCCESS = "SHOW_FEATURE_SUCCESS";

export const POST_FEATURE_REQUEST = "POST_FEATURE_REQUEST";
export const POST_FEATURE_SUCCESS = "POST_FEATURE_SUCCESS";

export const PUT_FEATURE_REQUEST = "PUT_FEATURE_REQUEST";
export const PUT_FEATURE_SUCCESS = "PUT_FEATURE_SUCCESS";

export const DELETE_FEATURE_REQUEST = "DELETE_FEATURE_REQUEST";
export const DELETE_FEATURE_SUCCESS = "DELETE_FEATURE_SUCCESS";
