export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";

export const SHOW_CATEGORY_REQUEST = "SHOW_CATEGORY_REQUEST";
export const SHOW_CATEGORY_SUCCESS = "SHOW_CATEGORY_SUCCESS";

export const POST_CATEGORY_REQUEST = "POST_CATEGORY_REQUEST";
export const POST_CATEGORY_SUCCESS = "POST_CATEGORY_SUCCESS";

export const PUT_CATEGORY_REQUEST = "PUT_CATEGORY_REQUEST";
export const PUT_CATEGORY_SUCCESS = "PUT_CATEGORY_SUCCESS";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
