import * as ACTIONS from "@iso/redux/filters/actionTypes";

const initialState = {
  filters: {
    list: [],
    total: 0,
  },
};

function filtersReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_FILTERS_SUCCESS: {
      return {
        ...state,
        ...{ filters: payload },
      };
    }

    default:
      return state;
  }
}

export default filtersReducer;
