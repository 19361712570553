export const GET_SPECIALIZATIONS_REQUEST = "GET_SPECIALIZATIONS_REQUEST";
export const GET_SPECIALIZATIONS_SUCCESS = "GET_SPECIALIZATIONS_SUCCESS";

export const SHOW_SPECIALIZATION_REQUEST = "SHOW_SPECIALIZATION_REQUEST";
export const SHOW_SPECIALIZATION_SUCCESS = "SHOW_SPECIALIZATION_SUCCESS";

export const POST_SPECIALIZATION_REQUEST = "POST_SPECIALIZATION_REQUEST";
export const POST_SPECIALIZATION_SUCCESS = "POST_SPECIALIZATION_SUCCESS";

export const PUT_SPECIALIZATION_REQUEST = "PUT_SPECIALIZATION_REQUEST";
export const PUT_SPECIALIZATION_SUCCESS = "PUT_SPECIALIZATION_SUCCESS";

export const DELETE_SPECIALIZATION_REQUEST = "DELETE_SPECIALIZATION_REQUEST";
export const DELETE_SPECIALIZATION_SUCCESS = "DELETE_SPECIALIZATION_SUCCESS";
