export const GET_LOCATIONS_REQUEST = "GET_LOCATIONS_REQUEST";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";

export const SHOW_LOCATION_REQUEST = "SHOW_LOCATION_REQUEST";
export const SHOW_LOCATION_SUCCESS = "SHOW_LOCATION_SUCCESS";

export const POST_LOCATION_REQUEST = "POST_LOCATION_REQUEST";
export const POST_LOCATION_SUCCESS = "POST_LOCATION_SUCCESS";

export const PUT_LOCATION_REQUEST = "PUT_LOCATION_REQUEST";
export const PUT_LOCATION_SUCCESS = "PUT_LOCATION_SUCCESS";

export const DELETE_LOCATION_REQUEST = "DELETE_LOCATION_REQUEST";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
