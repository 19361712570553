import * as ACTIONS from "@iso/redux/packages/actionTypes";

const initialState = {
  locations: {
    list: [],
    total: 0,
  },
};

function packagesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_PACKAGES_SUCCESS: {
      return {
        ...state,
        ...{ locations: payload },
      };
    }

    default:
      return state;
  }
}

export default packagesReducer;
