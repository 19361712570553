export const GET_SPECIFICTYPE_REQUEST = "GET_SPECIFICTYPE_REQUEST";
export const GET_SPECIFICTYPE_SUCCESS = "GET_SPECIFICTYPE_SUCCESS";

export const SHOW_SPECIFICTYPE_REQUEST = "SHOW_SPECIFICTYPE_REQUEST";
export const SHOW_SPECIFICTYPE_SUCCESS = "SHOW_SPECIFICTYPE_SUCCESS";

export const POST_SPECIFICTYPE_REQUEST = "POST_SPECIFICTYPE_REQUEST";
export const POST_SPECIFICTYPE_SUCCESS = "POST_SPECIFICTYPE_SUCCESS";

export const PUT_SPECIFICTYPE_REQUEST = "PUT_SPECIFICTYPE_REQUEST";
export const PUT_SPECIFICTYPE_SUCCESS = "PUT_SPECIFICTYPE_SUCCESS";

export const DELETE_SPECIFICTYPE_REQUEST = "DELETE_SPECIFICTYPE_REQUEST";
export const DELETE_SPECIFICTYPE_SUCCESS = "DELETE_SPECIFICTYPE_SUCCESS";
