export const GET_FILTERS_CATEGORIES_REQUEST = "GET_FILTERS_CATEGORIES_REQUEST";
export const GET_FILTERS_CATEGORIES_SUCCESS = "GET_FILTERS_CATEGORIES_SUCCESS";

export const SHOW_FILTER_CATEGORY_REQUEST = "SHOW_FILTER_CATEGORY_REQUEST";
export const SHOW_FILTER_CATEGORY_SUCCESS = "SHOW_FILTER_CATEGORY_SUCCESS";

export const POST_FILTER_CATEGORY_REQUEST = "POST_FILTER_CATEGORY_REQUEST";
export const POST_FILTER_CATEGORY_SUCCESS = "POST_FILTER_CATEGORY_SUCCESS";

export const PUT_FILTER_CATEGORY_REQUEST = "PUT_FILTER_CATEGORY_REQUEST";
export const PUT_FILTER_CATEGORY_SUCCESS = "PUT_FILTER_CATEGORY_SUCCESS";

export const DELETE_FILTER_CATEGORY_REQUEST = "DELETE_FILTER_CATEGORY_REQUEST";
export const DELETE_FILTER_CATEGORY_SUCCESS = "DELETE_FILTER_CATEGORY_SUCCESS";
