import * as ACTIONS from "@iso/redux/specificTypes/actionTypes";

const initialState = {
  specificTypes: {
    list: [],
    total: 0,
  },
};

function specificTypesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_SPECIFICTYPE_SUCCESS: {
      return {
        ...state,
        ...{ specificTypes: payload },
      };
    }

    default:
      return state;
  }
}

export default specificTypesReducer;
