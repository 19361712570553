export const GET_PACKAGES_OPTIONS_REQUEST = "GET_PACKAGES_OPTIONS_REQUEST";
export const GET_PACKAGES_OPTIONS_SUCCESS = "GET_PACKAGES_OPTIONS_SUCCESS";

export const SHOW_PACKAGE_OPTION_REQUEST = "SHOW_PACKAGE_OPTION_REQUEST";
export const SHOW_PACKAGE_OPTION_SUCCESS = "SHOW_PACKAGE_OPTION_SUCCESS";

export const POST_PACKAGE_OPTION_REQUEST = "POST_PACKAGE_OPTION_REQUEST";
export const POST_PACKAGE_OPTION_SUCCESS = "POST_PACKAGE_OPTION_SUCCESS";

export const PUT_PACKAGE_OPTION_REQUEST = "PUT_PACKAGE_OPTION_REQUEST";
export const PUT_PACKAGE_OPTION_SUCCESS = "PUT_PACKAGE_OPTION_SUCCESS";

export const DELETE_PACKAGE_OPTION_REQUEST = "DELETE_PACKAGE_OPTION_REQUEST";
export const DELETE_PACKAGE_OPTION_SUCCESS = "DELETE_PACKAGE_OPTION_SUCCESS";
