export const GET_LANGUAGES_REQUEST = "GET_LANGUAGES_REQUEST";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";

export const SHOW_LANGUAGE_REQUEST = "SHOW_LANGUAGE_REQUEST";
export const SHOW_LANGUAGE_SUCCESS = "SHOW_LANGUAGE_SUCCESS";

export const POST_LANGUAGE_REQUEST = "POST_LANGUAGE_REQUEST";
export const POST_LANGUAGE_SUCCESS = "POST_LANGUAGE_SUCCESS";

export const PUT_LANGUAGE_REQUEST = "PUT_LANGUAGE_REQUEST";
export const PUT_LANGUAGE_SUCCESS = "PUT_LANGUAGE_SUCCESS";

export const DELETE_LANGUAGE_REQUEST = "DELETE_LANGUAGE_REQUEST";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";
