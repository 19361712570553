export const GET_PARTNERS_REQUEST = "GET_PARTNERS_REQUEST";
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";

export const SHOW_PARTNER_REQUEST = "SHOW_PARTNER_REQUEST";
export const SHOW_PARTNER_SUCCESS = "SHOW_PARTNER_SUCCESS";

export const POST_PARTNER_REQUEST = "POST_PARTNER_REQUEST";
export const POST_PARTNER_SUCCESS = "POST_PARTNER_SUCCESS";

export const PUT_PARTNER_REQUEST = "PUT_PARTNER_REQUEST";
export const PUT_PARTNER_SUCCESS = "PUT_PARTNER_SUCCESS";

export const DELETE_PARTNER_REQUEST = "DELETE_PARTNER_REQUEST";
export const DELETE_PARTNER_SUCCESS = "DELETE_PARTNER_SUCCESS";
