import * as ACTIONS from "@iso/redux/categories/actionTypes";

const initialState = {
  categories: {
    list: [],
    total: 0,
  },
};

function categoriesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        ...{ categories: payload },
      };
    }

    default:
      return state;
  }
}

export default categoriesReducer;
